import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ControlsGapReportDto, getEnumOptions, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-controls-gap-report-result-item-form',
  templateUrl: './controls-gap-report-result-item-form.component.html',
  styleUrl: './controls-gap-report-result-item-form.component.scss',
})
export class ControlsGapReportResultItemFormComponent extends BaseForm<ControlsGapReportDto> implements OnInit {
  complianceReportTypesList = getEnumOptions(ControlsGapReportDto.ComplianceReportTypeEnum);
  complianceReportTypes = ControlsGapReportDto.ComplianceReportTypeEnum;
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.ControlsGapReportResult);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    // if (this.data?.policy) {
    //   this.formGroup?.controls?.policy?.disable();
    // } else {
    //   this.formGroup?.controls?.policy?.enable();
    // }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      policy: new FormControl(null, Validators.required),
      citations: new FormControl(null, Validators.required),
      complianceReportType: new FormControl(null, Validators.required),
      frequency: new FormControl(null, Validators.required),
      date: new FormControl(null),
      description: new FormControl(null),
    });
    // this.formGroup
    //   .get("complianceReportType")
    //   .valueChanges.subscribe((type) => {
    //     this.formGroup.removeControl('policy');
    //     this.formGroup.removeControl('citations');
    //     if (type == ComplianceReportDto.ComplianceReportTypeEnum.Citation) {
    //       this.formGroup.addControl("citations", new FormControl(null, Validators.required));
    //     } else {
    //       this.formGroup.addControl("policy", new FormControl(null, Validators.required));
    //     }
    //   });
  }
}
